footer {
  flex: 1;
  padding-bottom: 3rem;
}

.madeWithList, .socializeList {
  list-style: none;
  padding-left: 0rem;
  font-size: 1.1rem;
  line-height: 2rem;
}

.madeWithList a:link, .socializeList a:link {
  color: #bbbbbb;
}

.madeWithList a:visited, .socializeList a:visited {
  color: #bbbbbb;
}

.madeWithList a:hover, .socializeList a:hover {
  color: #cc0000;
}
