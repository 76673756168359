@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  flex: 1 1;
  padding-bottom: 3rem;
}

.madeWithList, .socializeList {
  list-style: none;
  padding-left: 0rem;
  font-size: 1.1rem;
  line-height: 2rem;
}

.madeWithList a:link, .socializeList a:link {
  color: #bbbbbb;
}

.madeWithList a:visited, .socializeList a:visited {
  color: #bbbbbb;
}

.madeWithList a:hover, .socializeList a:hover {
  color: #cc0000;
}

.AppContainer {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

